import React, { useState } from 'react'
import { AppContext as NextJSAppContext, AppProps } from 'next/app'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose, Store } from 'redux'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/react'
import rootReducers from '@store/redux/reducers'
import { analytics } from '@helpers/analytics'
import trackError from '@helpers/trackError'
import { isProdEnv, isBrowser } from '@helpers/env'
import AppComponent from '@components/App'
import '../components/global.styles.scss'
import {
  fetchContentfulHeaderFlashBanner,
  fetchContentfulHeader
} from '@api/graphqlContentFul/header'
import { getValueFromObject } from '@helpers/utils'
import { Router } from 'next/router'
import { EmptyObject, GenericFunction } from 'types/helpers'
import { PostHog } from '../global.d'
import { FlashBannerItem, MenuFlashBanner } from 'types/contentful/graphQlApi'
import AppContext from '@components/AppContext'
import { HeaderProps, HeaderTheme } from 'types/header'

const NO_LAYOUT = ['fit-finder', 'returns']

const IGNORED_SENTRY_ERRORS = [
  'Failed to load https://www.googletagmanager.com/'
]

export function getFlashBannerItem(flashBanner: MenuFlashBanner | undefined) {
  const flashBannerResponse = getValueFromObject(
    flashBanner,
    'menu.items[0].flashBannerItem',
    {}
  )
  const flashBannerItem = {
    ...flashBannerResponse,
    showBanner: Object.keys(flashBannerResponse).length > 0
  }

  return flashBannerItem
}

export function getHeaderData(header: HeaderProps | undefined) {
  return getValueFromObject(header, 'menu.items[0].links', {})
}

function getIgnoredSentryError(eventValue: string): string | null {
  return IGNORED_SENTRY_ERRORS.find(error => eventValue.includes(error)) || null
}

if (isBrowser()) {
  Sentry.init({
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // Facebook borked
      'fb_xd_fragment',
      `Can't find variable: _AutofillCallbackHandler`
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Bing
      /bat.bing.com/i,
      // tiktok
      /analytics.tiktok.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Ignore Google flakiness
      /\/(gtm|ga|analytics)\.js/i,
      /googleads.g.doubleclick.net/i,
      /\/tag\.js/i,
      /assets\/smart-tag/i,
      /sezzle-widget.min.js/i,
      /dev.visualwebsiteoptimizer.com/i,
      /staticw2.yotpo.com/i,
      /googletagmanager.com/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^@safari-web-extension:/i
    ],
    enabled: process.env.NODE_ENV !== 'development',
    environment: process.env.API_ENV,
    release: process.env.NEXT_BUILD_ID,
    dist: process.env.NEXT_BUILD_ID,
    dsn: 'https://114158a216294a3aafff074e7efc0227@o490003.ingest.sentry.io/5553275',
    autoSessionTracking: true,
    integrations: [],
    beforeSend: event => {
      const exceptionValues = event.exception?.values

      if (
        exceptionValues &&
        exceptionValues.some(({ value }) => {
          if (!value) return false
          const ignoredError = getIgnoredSentryError(value)
          return ignoredError && value.includes(ignoredError)
        })
      ) {
        return null
      }
      return event
    }
  })

  window.postHogAsyncLoader = new Promise(resolve => {
    window.analytics?.ready(() => {
      if (process.env.NODE_ENV !== 'development') {
        window.posthog.init(process.env.POSTHOG_API_KEY, {
          api_host: 'https://eu.posthog.com',
          disable_session_recording: true,
          segment: window.analytics, // Pass window.analytics here - NOTE: `window.` is important
          capture_pageview: false, // You want this false if you are going to use segment's `analytics.page()` for pageviews
          rageclick: true, // capturing when a user clicks three or more times (maximum 1s between each click) without moving their mouse
          loaded: (posthog: PostHog) => {
            posthog.onFeatureFlags(() => {
              if (posthog.isFeatureEnabled('sc_recording')) {
                posthog.startSessionRecording()
              }
            })
            resolve(posthog)
          }
        })
      }
    })
  })
}

let devtools: GenericFunction = f => f
if (process.browser && window.__REDUX_DEVTOOLS_EXTENSION__ && !isProdEnv()) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__()
}

const makeStore: (
  initialState: Record<string, unknown>
) => Store = initialState =>
  createStore(
    rootReducers,
    initialState,
    compose(
      applyMiddleware(thunk), // Add additional middleware here
      devtools
    )
  )

const store = makeStore({})

export function reportWebVitals(metric: {
  label: string
  name: string
  value: number
}) {
  if (metric.label === 'web-vital') {
    analytics(
      'Web Vitals',
      {
        category: 'Web Vitals',
        label: metric.name,
        action: metric.name,
        value: Math.round(
          metric.name === 'CLS' ? metric.value * 1000 : metric.value
        ),
        nonInteraction: 1
      },
      {
        integrations: {
          All: false,
          'Google Analytics': true,
          'Google Analytics 4': true
        }
      }
    )
  }
}

export type FlashBanner =
  | FlashBannerItem
  | EmptyObject
  | { showBanner: boolean }

type PageProps = Record<string, unknown> & {
  statusCode: number
  pageEntries?: Array<Record<string, string | undefined>>
}

type AppOwnProps = {
  handle: string
  route: Router['route']
  statusCode: number
  flashBannerFromServer: FlashBanner
  headerFromServer: HeaderProps
}

function MyApp({
  Component,
  pageProps,
  handle,
  route,
  flashBannerFromServer,
  headerFromServer,
  statusCode
}: AppProps & AppOwnProps) {
  const pageHeaderTheme =
    (pageProps?.pageEntries?.find(
      (entry: { headerTheme?: HeaderTheme }) => entry?.headerTheme !== undefined
    )?.headerTheme as HeaderTheme) || ('default' as HeaderTheme)

  const [headerTheme, setHeaderTheme] = useState(pageHeaderTheme)
  const [header, setHeader] = useState(headerFromServer)
  const [flashBanner, setFlashBanner] = useState(flashBannerFromServer)

  return (
    <Provider store={store}>
      <AppContext.Provider
        value={{
          flashBanner,
          header,
          headerTheme,
          setHeaderTheme,
          setHeader,
          setFlashBanner
        }}
      >
        <AppComponent
          statusCode={statusCode}
          includeNoFollow={pageProps?.includeNoFollow}
          noLayout={NO_LAYOUT.includes(handle) || NO_LAYOUT.includes(route)}
        >
          <Component {...pageProps} />
        </AppComponent>
      </AppContext.Provider>
    </Provider>
  )
}

MyApp.getInitialProps = async (
  context: NextJSAppContext
): Promise<AppOwnProps | undefined> => {
  try {
    const { router, Component } = context
    const isPreview = router.query.preview === 'true'

    const flashBanner = await fetchContentfulHeaderFlashBanner({
      preview: isPreview
    })

    const header = await fetchContentfulHeader({
      preview: isPreview
    })

    const componentPageProps = (
      Component.getInitialProps
        ? await Component.getInitialProps(context.ctx)
        : { statusCode: 200 }
    ) as PageProps

    const pageHandle = Array.isArray(router.query.pageHandle)
      ? router.query.pageHandle[0]
      : router.query.pageHandle || ''

    return {
      handle: pageHandle,
      flashBannerFromServer: getFlashBannerItem(flashBanner),
      route: context.router.route,
      statusCode: componentPageProps?.statusCode,
      headerFromServer: getHeaderData(header)
    }
  } catch (e) {
    const menuError = new Error('Could not get menu from contentful')
    const error = e instanceof Error ? e : menuError
    trackError(new Error('Could not get menu from contentful'), { error })
    return undefined
  }
}

export default MyApp
