import { useContext } from 'react'
import FitFinderDrawerOverlay from '@components/FitFinder'
import CartSideDrawerOverlay from '@components/cart/CartSideDrawerOverlay'
import FlashBanner from '@components/FlashBanner'
import { analytics } from '@helpers/analytics'
import { useDispatch, useSelector } from 'react-redux'
import { closeCart, openCart } from '@store/redux/actions/otherActions'
import { useFitFinderToggle } from '@components/FitFinder/hooks/useFitFinderToggle'
import FitFinderPromoBanner, {
  CartVisibilityState
} from '@components/ui/FitFinderPromoBanner'
import { HeaderWrapper } from './HeaderWrapper'
import styles from './styles.module.scss'
import AppContext from '@components/AppContext'
import classNames from 'classnames'
import useScrollPosition from '@helpers/hooks/useScrollPosition'
import { FLASH_BANNER_HEIGHT } from '@consts/index'
import { HEADER_ANALYTICS_EVENT_NAMES } from './consts'

export type CartEvent = {
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
}

export default function Header() {
  const scrollPosition = useScrollPosition()
  const { header, headerTheme } = useContext(AppContext)

  const dispatch = useDispatch()
  const { openFitFinder, closeFitFinder, isFitFinderOpen } =
    useFitFinderToggle()

  const isCartOpen = useSelector(
    (state: CartVisibilityState) => state.isCartOpen
  )

  const isInScrollPositionRange = scrollPosition < FLASH_BANNER_HEIGHT

  const classes = classNames(styles.HeaderContainer, {
    [styles.HeaderContainer__transparentLight]:
      headerTheme === 'transparent-light' && isInScrollPositionRange,
    [styles.HeaderContainer__transparentDark]:
      headerTheme === 'transparent-dark' && isInScrollPositionRange
  })

  const triggerOpenCart = () => dispatch(openCart())
  const triggerCloseCart = () => dispatch(closeCart())

  const onCartOpen = (e: CartEvent['event']) => {
    e.preventDefault()
    if (!isCartOpen) {
      analytics('Click', {
        category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
        label: 'Basket opened'
      })
      triggerOpenCart()
    }
  }

  return (
    <>
      <FlashBanner />
      <CartSideDrawerOverlay
        isCartOpen={isCartOpen}
        closeCart={triggerCloseCart}
        openCart={triggerOpenCart}
      />
      <FitFinderDrawerOverlay
        isFitFinderOpen={isFitFinderOpen}
        onCloseFitFinder={closeFitFinder}
      />
      <div className={classes}>
        <HeaderWrapper
          header={header}
          isCartOpen={isCartOpen}
          onCartOpen={onCartOpen}
          isFitFinderOpen={isFitFinderOpen}
        />
        <FitFinderPromoBanner onOpenFitFinder={openFitFinder} />
      </div>
    </>
  )
}
