import React from 'react'
import { getLink } from '@helpers/env'
import Badge, { BadgeProps } from '@components/ui/Badge'
import styles from './styles.module.scss'

type CardImgProps = {
  badge?: BadgeProps
  title: string
  imgSrc: string
  link: string
  onClickHandler: (arg: string) => void
  imgSrcSet?: string
}

function CardImg({
  badge,
  link,
  title,
  imgSrc,
  imgSrcSet,
  onClickHandler
}: CardImgProps) {
  const linkClicked = () => onClickHandler('Image')

  return (
    <div className={styles.productBreakdownImage}>
      {!!link ? (
        <a
          className={styles.productBreakdownImage__link}
          href={getLink(link)}
          onClick={linkClicked}
        >
          <img
            className={styles.productBreakdownImage__image}
            alt={title}
            width="287"
            height="287"
            src={imgSrc}
            srcSet={imgSrcSet}
          />
        </a>
      ) : (
        <div className={styles.productBreakdownImage__link}>
          {!!badge && (
            <div className={styles.productBreakdownImage__badge}>
              <Badge {...badge} fullWidth />
            </div>
          )}
          <img
            className={styles.productBreakdownImage__image}
            alt={title}
            width="287"
            height="287"
            src={imgSrc}
            srcSet={imgSrcSet}
          />
        </div>
      )}
    </div>
  )
}

export default CardImg
