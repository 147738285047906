const usCommon = {
  common: {
    sweetenersList: {
      'option-1': 'Free Express Shipping on orders over',
      'option-2': 'Hassle-free returns',
      'option-3': '200 free returns'
    },
    cart: {
      multibuy: {
        trunks: {
          label: '3 for $80 or 5 for $120',
          discount: '',
          eligiblePrice: '32.00'
        },
        't-shirts': {
          label: '3 for $150',
          discount: 'Save $30',
          eligiblePrice: '60.00'
        },
        belts: {
          label: '$20 off',
          discount: '',
          eligiblePrice: '125.00'
        }
      }
    }
  }
}

module.exports = usCommon
