import {
  productsBreakdownByIdQuery,
  productsBreakdownByProductCategoryQuery,
  productsBreakdownByHandleQuery
} from './queries/productsBreakdownQuery'
import { fetchContentfulService } from '.'
import { ProductsBreakdownProps } from 'types/contentful/graphQlApi'

type FetchContentfulProductsBreakdownByHandle = {
  pageHandle: string
  preview?: boolean
}
export const fetchContentfulProductsBreakdownByHandle = (
  variables: FetchContentfulProductsBreakdownByHandle
) =>
  fetchContentfulService<
    FetchContentfulProductsBreakdownByHandle,
    ProductsBreakdownProps
  >({
    query: productsBreakdownByHandleQuery,
    errorMessage: 'Error fetching contentful products breakdown',
    variables
  })

type FetchContentfulProductsBreakdownByProductCategory = {
  productCategory: string
  preview?: boolean
}
export const fetchContentfulProductsBreakdownByProductCategory = (
  variables: FetchContentfulProductsBreakdownByProductCategory
) =>
  fetchContentfulService<
    FetchContentfulProductsBreakdownByProductCategory,
    ProductsBreakdownProps
  >({
    query: productsBreakdownByProductCategoryQuery,
    errorMessage: 'Error fetching contentful products breakdown',
    variables
  })

type FetchContentfulProductsBreakdownById = {
  id: string
  preview?: boolean
}
export const fetchContentfulProductsBreakdownById = (
  variables: FetchContentfulProductsBreakdownById
) =>
  fetchContentfulService<
    FetchContentfulProductsBreakdownById,
    ProductsBreakdownProps
  >({
    query: productsBreakdownByIdQuery,
    errorMessage: 'Error fetching contentful products breakdown',
    variables
  })
