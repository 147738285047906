import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { analytics } from '@helpers/analytics'
import { setCookie } from '@helpers/session'
import { formatUrl, prefixLocale, replaceLocale } from '@helpers/env'
import { ObjectValues } from 'types/helpers'
import { ReactComponent as UKFlagIcon } from '@svgs/uk-flag.svg'
import { ReactComponent as EUFlagIcon } from '@svgs/eu-flag.svg'
import { ReactComponent as USFlagIcon } from '@svgs/us-flag.svg'
import { ReactComponent as ArrowDownIcon } from '@svgs/arrow-down.svg'
import { LOCALES, COOKIE_EXPIRE_1_MONTH } from '@consts/index'
import styles from './styles.module.scss'

const COUNTRIES = {
  gb: {
    name: 'United Kingdom',
    value: 'gb'
  },
  us: {
    name: 'USA',
    value: 'us'
  },
  eu: {
    name: 'Europe',
    value: 'eu'
  }
} as const

const countryIcons = {
  gb: UKFlagIcon,
  eu: EUFlagIcon,
  us: USFlagIcon
} as const

type CountryItem = ObjectValues<typeof COUNTRIES>

type CountryLocale = keyof typeof COUNTRIES

function getInitialLocale() {
  return (prefixLocale() || 'gb').replace(/\//, '') as CountryLocale
}

type CountryItemProps = {
  onClick: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    value: CountryItem['value']
  ) => void
  item: CountryItem
  selectedCountry: CountryLocale
  routerPath: string
}

function CountryItem({
  onClick,
  item,
  selectedCountry,
  routerPath
}: CountryItemProps) {
  const IconCountry = countryIcons[item.value]
  return (
    <a
      href={`${item.value === 'gb' ? '' : `/${item.value}`}${formatUrl(
        routerPath
      )}`}
      className={`${styles.countrySwitcherContent} ${
        item.value === selectedCountry
          ? styles.countrySwitcherContentLinkSelected
          : ''
      }`}
      onClick={e => onClick(e, item.value)}
      key={item.value}
      title={item.name}
      hrefLang={LOCALES[
        item?.value.toUpperCase() as Uppercase<CountryLocale>
      ].toLowerCase()}
      rel="alternate"
      aria-label={`Switch to our ${item.name} store`}
    >
      <IconCountry
        width="30"
        height="22"
        className={styles.countrySwitcherIcon}
      />
      <p className={styles.countrySwitcherValue}>{item.name}</p>
    </a>
  )
}

export function CountrySwitcher() {
  const { asPath, query } = useRouter()
  const [selectedCountry, setSelectedCountry] = useState<CountryLocale>(() =>
    getInitialLocale()
  )
  const [showDropdown, setShowDropdown] = useState(false)

  const handleSelectClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    value: CountryLocale
  ) => {
    event.preventDefault()
    if (value !== selectedCountry) {
      setSelectedCountry(value)
      setCookie('th_chosen', value.toUpperCase(), COOKIE_EXPIRE_1_MONTH, true)
      analytics('Click', {
        category: 'Country Switcher',
        label: `Country Switcher ${COUNTRIES[value].name}`
      })
      const url = replaceLocale(value === 'gb' ? '' : `/${value}`)
      window.location.href = url
    }
  }

  const currentCountry = COUNTRIES[selectedCountry]
  const SelectedCountryIcon = countryIcons[currentCountry.value]

  const countriesToRender = Object.values(COUNTRIES)

  return (
    <div className={styles.countrySwitcher}>
      <div
        className={styles.countrySwitcherContainer}
        role="button"
        tabIndex={0}
        aria-pressed={showDropdown}
        onClick={() => setShowDropdown(currentState => !currentState)}
        onKeyDown={() => setShowDropdown(currentState => !currentState)}
      >
        <div className={styles.countrySwitcherContent}>
          <SelectedCountryIcon
            width="30"
            className={styles.countrySwitcherIcon}
          />
          <p className={styles.countrySwitcherValue}>
            {currentCountry.name}
            <span
              className={styles.countrySwitcherArrow}
              data-open={showDropdown}
            >
              <ArrowDownIcon
                stroke="white"
                aria-label="country selector arrow icon"
              />
            </span>
          </p>
        </div>
        <div
          className={`${styles.countrySwitcherSelectorWrapper} ${
            showDropdown ? styles.countrySwitcherSelectorWrapper__open : ''
          }`}
        >
          {countriesToRender.map(item => (
            <CountryItem
              key={item?.name}
              item={item}
              onClick={handleSelectClick}
              routerPath={asPath}
              selectedCountry={selectedCountry}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default CountrySwitcher
