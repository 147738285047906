import { getLocale } from '@helpers/env'
import productsBreakdownFragment from '../fragments/productsBreakdown'

export const productsBreakdownByHandleQuery = `query($pageHandle:  String!, $preview: Boolean) {
  productsBreakdown: productBreakdownCollection(where: {pageHandlesToDisplayBreakdown_contains_some: [$pageHandle]}, locale: "${getLocale()}", preview: $preview) {
    items {
      ${productsBreakdownFragment}
    }
  }
}`

export const productsBreakdownByProductCategoryQuery = `query($productCategory:  String!, $preview: Boolean) {
  productsBreakdown: productBreakdownCollection(where: {productCategory_contains_some: [$productCategory]}, locale: "${getLocale()}", preview: $preview) {
    items {
      ${productsBreakdownFragment}
    }
  }
}`

export const productsBreakdownByIdQuery = `query($id:  String!, $preview: Boolean) {
  productsBreakdown: productBreakdown(id: $id, locale: "${getLocale()}", preview: $preview) {
    ${productsBreakdownFragment}
  }
}`
