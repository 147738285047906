/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import LazyLoad from 'react-lazyload'
import Image from '@components/ui/Image'
import trackError from '@helpers/trackError'
import { File } from 'types/contentful/restApi'
import styles from './styles.module.scss'

export type MediaItemProps = {
  poster?: {
    url: string
  }
  alt?: string
  title?: string
  fileName?: string
  description?: string
  contentType?: string
  type?: string
  file: File
  onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const isValidUrl = (url?: string) => {
  const urlPatternMatch = url?.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  )
  return urlPatternMatch != null
}

function MediaItem({
  poster,
  alt,
  title,
  description: iframeUrlValue,
  contentType,
  type,
  file: { details, ...file },
  fileName,
  onClick = () => undefined
}: MediaItemProps) {
  const mediaContentType = file?.contentType || contentType || type || ''
  const iframeUrl = isValidUrl(iframeUrlValue) ? iframeUrlValue : null
  const assetUrl = /html/.test(mediaContentType) ? iframeUrl : file?.url

  if (!assetUrl) {
    trackError(
      new Error(
        `Media Item url for asset content-type=${mediaContentType} doesn't exist`
      ),
      {
        title
      }
    )
    return null
  }

  return (
    <div className={styles.mediaWrapper} onClick={onClick}>
      {/video/.test(mediaContentType) && (
        <LazyLoad once>
          <div className={styles.media__video}>
            <video
              poster={poster?.url}
              title={alt || title || fileName}
              autoPlay
              preload="metadata"
              crossOrigin="anonymous"
              playsInline
              loop
              muted
              width={details?.image?.width}
              height={details?.image?.height}
            >
              <source src={`${assetUrl}#t=0.1`} type={mediaContentType} />
            </video>
          </div>
        </LazyLoad>
      )}
      {/image/.test(mediaContentType) && (
        <Image
          alt={alt || title || fileName || ''}
          src={`https:${assetUrl}?q=80`}
          objectFit="contain"
          width={details?.image?.width}
          height={details?.image?.height}
        />
      )}
      {/html/.test(mediaContentType) && (
        <LazyLoad once>
          <div className={styles.media__iframe}>
            <iframe
              title={alt || title || fileName}
              src={`${assetUrl}#t=0.1`}
              width={details?.image?.width}
              height={details?.image?.height}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </div>
        </LazyLoad>
      )}
    </div>
  )
}
export default MediaItem
