import { useState } from 'react'
import SectionHeader from '@components/ui/SectionHeader'
import FeatureCardsTabs from '../shared/FeatureCardsTabs'
import FeatureCardsCopy from '../shared/FeatureCardsCopy'
import { Cards, ContentFulPageProps, OnThumbnailClick } from '../types'
import styles from './styles.module.scss'
import genericStyles from '../styles.module.scss'
import MediaItem from '@components/ui/Medias/MediaItem'

type FeatureCardsDefaultProps = {
  cards: Cards
  title: string
  copyAlignment: ContentFulPageProps['copyAlignment']
  onThumbnailClick: OnThumbnailClick
}
export default function FeatureCardsDefault({
  cards,
  title,
  copyAlignment,
  onThumbnailClick
}: FeatureCardsDefaultProps) {
  const [selectedCardIndex, setSelectedCardIndex] = useState<number>(0)

  const selectedCard = cards[selectedCardIndex]

  return (
    <>
      <div className={styles.SectionHeaderWrapper}>
        <SectionHeader title={title} variant="left_aligned" />
      </div>
      <div
        className={genericStyles.FeatureCards__wrapper}
        style={
          {
            '--text-color': '#001e3c',
            '--text-transition': 'color 0.2s cubic-bezier(0.4, 0, 0.6, 1)'
          } as React.CSSProperties
        }
      >
        <div
          className={`${styles.FeatureCards__image}  ${
            copyAlignment === 'right' ? styles.FeatureCards__image_reverse : ''
          }`}
        >
          {selectedCard.media?.file ? (
            <MediaItem
              key={`${selectedCard?.media?.title || ''}`}
              {...selectedCard?.media}
              file={selectedCard?.media?.file}
            />
          ) : (
            <picture>
              <source
                media="(min-width: 700px)"
                srcSet={selectedCard.images.desktop.url}
              />
              <img
                alt={selectedCard.images.mobile.alt}
                src={
                  selectedCard.images.mobile.url ||
                  selectedCard.images.desktop.url
                }
              />
            </picture>
          )}
        </div>
        <div
          className={`${styles.FeatureCards__content} ${
            cards.length === 1
              ? styles.FeatureCards__content_verticalCenter
              : ''
          } ${
            copyAlignment === 'right'
              ? styles.FeatureCards__content_reverse
              : ''
          }`}
        >
          <FeatureCardsTabs
            cards={cards}
            selectedCardIndex={selectedCardIndex}
            setSelectedCardIndex={setSelectedCardIndex}
            onThumbnailClick={onThumbnailClick}
            variant="default"
          />

          {selectedCard.title && (
            <div className={genericStyles.FeatureCards__sectionTitle}>
              {selectedCard.title}
            </div>
          )}

          <FeatureCardsCopy copy={selectedCard.copy} variant="default" />
        </div>
      </div>
    </>
  )
}
