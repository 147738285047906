import AppContext from '@components/AppContext'
import { FLASH_BANNER_HEIGHT } from '@consts/index'
import useScrollPosition from '@helpers/hooks/useScrollPosition'
import { useContext, useEffect, useMemo, useState } from 'react'

const COLORS = {
  dark: '#001e3c',
  light: '#ffffff',
  transparent: 'transparent'
}

function getThemeColor(
  enabled: boolean,
  isInScrollPositionRange: boolean,
  theme: string
) {
  if (theme === 'transparent-light' && isInScrollPositionRange) {
    return enabled ? COLORS.light : COLORS.dark
  }

  return COLORS.dark
}

function getThemeBackgroundColor(
  isTransparent: boolean,
  isInScrollPositionRange: boolean
) {
  return isTransparent && isInScrollPositionRange
    ? COLORS.transparent
    : COLORS.light
}

export default function useHeaderTheme() {
  const scrollPosition = useScrollPosition()
  const { headerTheme } = useContext(AppContext)
  const [transparentMode, setTransparentMode] = useState<boolean>(false)

  const isInScrollPositionRange = useMemo(
    () => scrollPosition < FLASH_BANNER_HEIGHT,
    [scrollPosition]
  )

  useEffect(() => {
    setTransparentMode(
      headerTheme === 'transparent-light' || headerTheme === 'transparent-dark'
    )
  }, [headerTheme])

  useEffect(() => {
    const isHeaderTransparent =
      transparentMode &&
      isInScrollPositionRange &&
      (headerTheme === 'transparent-light' ||
        headerTheme === 'transparent-dark')

    const themeColor = getThemeColor(
      isHeaderTransparent,
      isInScrollPositionRange,
      headerTheme
    )
    const themeBackgroundColor = getThemeBackgroundColor(
      isHeaderTransparent,
      isInScrollPositionRange
    )

    const updateHeaderTheme = () => {
      document.body.style.setProperty(
        '--header-background-theme',
        themeBackgroundColor
      )
      document.body.style.setProperty('--header-color-theme', themeColor)
      document.body.style.setProperty(
        '--header-transition',
        '0.3s cubic-bezier(0.4, 0, 0.6, 1)'
      )
    }

    updateHeaderTheme()
  }, [transparentMode, headerTheme, isInScrollPositionRange])

  const setHeaderTransparent = (value: boolean) => {
    if (isInScrollPositionRange) {
      setTransparentMode(value)
    }
  }

  return {
    setHeaderTransparent
  }
}
