import React, { Fragment } from 'react'
import classnames from 'classnames'
import { isBrowser } from '@helpers/env'
import { upperFirstLetter, getValueFromObject } from '@helpers/utils'
import { analytics } from '@helpers/analytics'
import CardDetailed from '@components/ui/cards/CardDetailed'
import CardMinimal from '@components/ui/cards/CardMinimal'
import { Category } from '../'
import styles from './styles.module.scss'

export type CardItem = {
  title: string
  badge?: {
    title: string
  }
  byline: string
  description: string
  type: string
  foregroundColor: string
  url: string
  image: {
    url?: string
    file?: {
      url: string
    }
  }
}

export function getPageCategoryProductBreakdown() {
  if (!isBrowser()) return null

  const urlPathname = window.location.pathname
  switch (true) {
    case urlPathname.includes('collections'):
      return 'Collection Page'

    case urlPathname.includes('products'):
      return 'Product Page'

    case urlPathname.includes('pages'):
      return 'Landing Page'

    default:
      return 'Homepage'
  }
}

export type SendEventProperties = {
  componentType: string
  category: string
  title: string
  firstTimeUser: boolean
}

const sendEvent = ({
  componentType,
  category,
  title,
  firstTimeUser
}: SendEventProperties) => {
  const props = {
    action: `Breakdown Collection ${componentType}`,
    category: category || getPageCategoryProductBreakdown(),
    label: !!title
      ? upperFirstLetter(title.toLowerCase())
      : 'Collection Breakdown',
    type: firstTimeUser ? 'New' : 'Existing'
  }
  analytics('Click', props)
}

type CardProps = {
  card: CardItem
  firstTimeUser: boolean
  category: Category
  customCategory?: string
}

function Card({
  card,
  firstTimeUser,
  category,
  customCategory = ''
}: CardProps) {
  const image =
    getValueFromObject(card, 'image.url', '') ||
    getValueFromObject(card, 'image.file.url', '')

  const eventHandler = ({
    componentType,
    title
  }: {
    componentType: string
    title: string
  }) => {
    sendEvent({
      componentType,
      title,
      category: category === 'custom' ? customCategory : category,
      firstTimeUser
    })
  }

  switch (card.type) {
    case 'cardMinimal':
      return <CardMinimal {...card} image={image} />

    default:
      return (
        <CardDetailed
          imgSrc={`${image}?w=486`}
          onClickHandler={eventHandler}
          {...card}
        />
      )
  }
}

type PBGridProps = {
  cards: CardItem[]
  firstTimeUser: boolean
  category: Category
  withBadge: boolean
  visible?: boolean
}

const PBGrid = ({
  cards,
  firstTimeUser,
  category,
  withBadge,
  visible = false
}: PBGridProps) => {
  return (
    <div
      className={classnames(
        `${styles.productsBreakdownGrid} ${
          withBadge ? styles.productsBreakdownGridWithBadge : ''
        } ${visible ? styles.productsBreakdownGridActive : ''}`
      )}
    >
      <ul className={classnames(`${styles.productsBreakdownGridList}`)}>
        {cards.slice(0, 5).map((card, index) => {
          return (
            <Fragment key={`${card.title}-${index}`}>
              {index === 0 && <li className={styles.spacer} />}
              <li
                className={classnames(
                  `${styles.productsBreakdown__item} grid__item--nogrow grid`
                )}
              >
                <div
                  className={classnames(
                    `${styles.productsBreakdown__itemWrapper} grid__container`
                  )}
                >
                  <Card
                    card={card}
                    firstTimeUser={firstTimeUser}
                    category={category}
                  />
                </div>
              </li>
              <li className={styles.spacer} />
            </Fragment>
          )
        })}
      </ul>
    </div>
  )
}

export default PBGrid
