type MetaProps = {
  includeNoFollow: boolean
}

function Meta({ includeNoFollow }: MetaProps) {
  const shouldIncludeNoFollow =
    includeNoFollow || process.env.API_ENV !== 'production'

  return (
    <>
      {shouldIncludeNoFollow && (
        <meta name="robots" content="nofollow, noindex" key="spoke-nofollow" />
      )}
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="SPOKE" />
      <link rel="icon" type="image/png" href="/static/favicon.ico" />
      <link rel="apple-touch-icon" href="/static/apple-touch-favicon.png" />
    </>
  )
}

export default Meta
