import HeaderLogo from '../../shared/HeaderLogo'
import SecondaryNavLinks from './SecondaryNavLinks'
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { PrimaryNavLinks } from './PrimaryNavLinks'
import { HeaderLinks } from 'types/header'
import { getCookieByName } from '@helpers/session'
import { flyoutMenuTrackerAction } from 'store/redux/actions/popUpsTrackerActions'
import { CartEvent } from '@components/Header'
import useHeaderTheme from '@components/Header/useHeaderThemeHook'
import { analytics } from '@helpers/analytics'
import { HEADER_ANALYTICS_EVENT_NAMES } from '@components/Header/consts'

export default function DesktopHeader({
  data,
  onCartIsOpen,
  onCartOpen
}: {
  data: HeaderLinks
  onCartIsOpen: boolean
  onCartOpen: (e: CartEvent['event']) => void
}) {
  const dispatch = useDispatch()
  const { setHeaderTransparent } = useHeaderTheme()
  const [menuState, setMenuState] = useState({
    showFlyoutMenu: false,
    currentNav: ''
  })

  const linksWithSubmenu = useMemo(
    () =>
      data
        ?.filter(link => link?.grid && Object.keys(link?.grid).length > 0)
        .map(link => link?.title?.toLowerCase()) || [],
    [data]
  )

  const resetMenu = useCallback(() => {
    setMenuState({
      showFlyoutMenu: false,
      currentNav: ''
    })
    setHeaderTransparent(true)
  }, [])

  const handleHoverMenuItems = useCallback(
    (title: string) => {
      const isFlyoutMenuVisible = linksWithSubmenu.includes(
        title?.toLowerCase()
      )
      setMenuState({
        showFlyoutMenu: isFlyoutMenuVisible,
        currentNav: title
      })
    },
    [linksWithSubmenu]
  )

  const handleMouseLeave = useCallback(() => {
    resetMenu()
  }, [resetMenu])

  const handleLogoMouseEnter = useCallback(() => {
    setMenuState({
      showFlyoutMenu: false,
      currentNav: ''
    })
    setHeaderTransparent(false)
  }, [setHeaderTransparent])

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault()
      setHeaderTransparent(false)
    },
    [setHeaderTransparent]
  )

  const handleLogoClick = useCallback(() => {
    analytics('Click', {
      category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
      label: 'Logo'
    })
  }, [])

  useEffect(() => {
    if (!getCookieByName('hideNewsletterPrompt')) {
      dispatch(flyoutMenuTrackerAction({ flyoutMenu: false }))
    }
  }, [dispatch, menuState.showFlyoutMenu, onCartIsOpen])

  return (
    <div onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
      <div className={styles.DesktopHeader}>
        <HeaderLogo
          width="61"
          height="52"
          onMouseEnter={handleLogoMouseEnter}
          onClick={handleLogoClick}
          classes={
            menuState.currentNav ? styles.DesktopHeader__headerLogoInactive : ''
          }
        />
        <PrimaryNavLinks
          links={data || []}
          currentNav={menuState.currentNav}
          handleShowMegamenu={handleHoverMenuItems}
          showFlyoutMenu={menuState.showFlyoutMenu}
        />
        <SecondaryNavLinks onCartOpen={onCartOpen} />
      </div>
      <div
        className={styles.DesktopHeader__fullBackground}
        data-state={menuState.showFlyoutMenu ? 'open' : 'closed'}
        onMouseEnter={resetMenu}
      />
    </div>
  )
}
