import trackError from '@helpers/trackError'
import gbCommon from '../locales/gb/common'
import usCommon from '../locales/us/common'
import euCommon from '../locales/eu/common'

import gbPages from '../locales/gb/pages'
import usPages from '../locales/us/pages'
import euPages from '../locales/eu/pages'

import gbProduct from '../locales/gb/product'
import usProduct from '../locales/us/product'
import euProduct from '../locales/eu/product'

import gbCollection from '../locales/gb/collection'
import usCollection from '../locales/us/collection'
import euCollection from '../locales/eu/collection'

// Define the structure of the translation objects
interface TranslationObject {
  [key: string]: string | TranslationObject
}

interface TranslationStrings {
  [locale: string]: TranslationObject
}

const translationStrings: TranslationStrings = {}

translationStrings.default = {
  ...gbCommon,
  ...gbPages,
  ...gbProduct,
  ...gbCollection
}

translationStrings.GB = {
  ...gbCommon,
  ...gbPages,
  ...gbProduct,
  ...gbCollection
}

translationStrings.US = {
  ...usCommon,
  ...usPages,
  ...usProduct,
  ...usCollection
}

translationStrings.EU = {
  ...euCommon,
  ...euPages,
  ...euProduct,
  ...euCollection
}

const Translations = {
  localise: (stringToTranslate: string): string => {
    try {
      const locale = process.env.SHOP_LOCALE || 'default'
      const localisedString = stringToTranslate
        .split('.')
        .reduce<TranslationObject | string | null>((prev, curr) => {
          if (prev && typeof prev === 'object') {
            return prev[curr] as TranslationObject | string | null
          }
          return null
        }, translationStrings[locale])

      if (typeof localisedString === 'string') {
        return localisedString
      }

      const defaultLocalisedString = stringToTranslate
        .split('.')
        .reduce<TranslationObject | string | null>((prev, curr) => {
          if (prev && typeof prev === 'object') {
            return prev[curr] as TranslationObject | string | null
          }
          return null
        }, translationStrings.default)

      if (typeof defaultLocalisedString === 'string') {
        return defaultLocalisedString
      }

      trackError(new Error(`Missing Translation - ${stringToTranslate}`), {
        stringToTranslate,
        locale
      })
      return `missing-translation.${locale}.${stringToTranslate}`
    } catch (e) {
      trackError(new Error(`Missing Translation - ${stringToTranslate}`), {
        stringToTranslate,
        locale: process.env.SHOP_LOCALE
      })
      return 'missing-translation.missing-string'
    }
  }
}

export default Translations
