import { ButtonLink } from '@components/ui/Button'
import styles from './styles.module.scss'
import {
  documentToReactComponents,
  Options
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document } from '@contentful/rich-text-types'
import { ReactElement, ReactNode } from 'react'

const Text = ({ children }: { children: ReactNode }) => <p>{children}</p>

const containsValue = (node: ReactNode): boolean => {
  if (Array.isArray(node)) {
    return node.some(child => containsValue(child as unknown as ReactNode))
  }
  if (typeof node === 'object' && node !== null) {
    const element = node as ReactElement
    if (element.props?.children) {
      return containsValue(element.props.children)
    }
  }
  if (typeof node === 'string') {
    return node === 'embedded-entry-inline'
  }
  return false
}

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (containsValue(children)) {
        return node.content.map(childNode => {
          if (childNode.nodeType === 'embedded-entry-inline') {
            const { buttonStyle, text, uri } =
              childNode.data.target.fields || {}
            return (
              <ButtonLink
                key={`${uri}`}
                href={uri}
                variant={buttonStyle}
                classes={styles.FeatureCards__contentButton}
              >
                {text}
              </ButtonLink>
            )
          }
        })
      }
      return <Text key={`${children}`}>{children}</Text>
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { url, details, contentType } = node.data.target.fields.file

      if (contentType === 'image/svg+xml') {
        return (
          <div
            className={styles.svgIcon}
            key={`${url}`}
            style={
              {
                maskImage: `url(${url})`,
                WebkitMaskImage: `url(${url})`,
                '--svgIcon-width': `${details.image.width}px`,
                '--svgIcon-height': `${details.image.height}px`
              } as React.CSSProperties
            }
          />
        )
      }

      return (
        <img
          src={url}
          alt={node.data.target.fields.title}
          width={details.size.width}
          height={details.size.height}
          key={`${url}`}
        />
      )
    }
  }
}

type FeatureCardsCopyProps = {
  copy: Document[]
  variant: 'default' | 'full_bleed'
}

export default function FeatureCardsCopy({
  copy,
  variant = 'default'
}: FeatureCardsCopyProps) {
  return (
    <div className={`${styles.FeatureCards__copy} ${styles[variant]}`}>
      {copy.map(node => documentToReactComponents(node, options))}
    </div>
  )
}
