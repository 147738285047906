const cardMinimalFragment = `
  foregroundColor,
  title,
  image {
    title,
    description,
    contentType,
    fileName,
    size,
    url,
    width,
    height
  },
  url
`

const productCardFragment = `
  description,
  title,
  badge {
    title,
      badgeType,
      themeColour,
      small,
      iconName
  },
  byline,
  hideTitle,
  image {
    title,
    description,
    contentType,
    fileName,
    size,
    url,
    width,
    height
  },
  button {
    title,
      text,
      uri,
      classes,
      event,
      buttonStyle
  },
  price {
    contentModelTitle,
      price,
      showFromCopy
  }
`

const productsBreakdownFragment = `
  category,
  title,
  byline,
  categories: categoriesCollection(limit: 5) {
    items {
      ...on CardMinimal {
        ${cardMinimalFragment}
      },
      ...on ProductCard {
        ${productCardFragment},
        colour
      }
    }
  },
  toggle,
  pageHandlesToDisplayBreakdown,
  visible
`

export default productsBreakdownFragment
