import { Card, Cards, OnThumbnailClick } from '../../types'
import styles from './styles.module.scss'

type TabVariant = 'default' | 'minimal'

type FeatureCardsTabsProps = {
  cards: Cards
  selectedCardIndex: number
  setSelectedCardIndex: (index: number) => void
  onThumbnailClick: OnThumbnailClick
  variant: TabVariant
}

type FeatureCardsTabsButtonProperties = {
  card: Card
  selectedCardIndex: number
  setSelectedCardIndex: (index: number) => void
  onThumbnailClick: OnThumbnailClick
  variant: TabVariant
  disabled?: boolean
}

function FeatureCardsTabsButton({
  card,
  disabled = false,
  selectedCardIndex,
  setSelectedCardIndex,
  onThumbnailClick,
  variant = 'default'
}: FeatureCardsTabsButtonProperties) {
  if (!card.thumbnail.image.url) {
    return null
  }

  return (
    <button
      className={`${styles.FeatureCards__button} ${
        disabled ? styles.FeatureCards__buttonMinimal : ''
      } ${card.id === selectedCardIndex && !disabled ? styles.selected : ''}`}
      onClick={ev => {
        setSelectedCardIndex(card.id)
        onThumbnailClick(ev, card.id)
      }}
      disabled={disabled}
    >
      <img
        className={`${styles.FeatureCards__icon} ${
          disabled ? styles.FeatureCards__iconMinimal : ''
        } ${styles[variant]}`}
        src={card.thumbnail.image.url}
        alt={card.thumbnail.label}
      />
      {!disabled && <p>{card.thumbnail.label}</p>}
    </button>
  )
}

export default function FeatureCardsTabs({
  cards,
  selectedCardIndex,
  setSelectedCardIndex,
  onThumbnailClick,
  variant = 'default'
}: FeatureCardsTabsProps) {
  return (
    <div className={styles.FeatureCards__buttons}>
      {cards.length === 1 ? (
        <FeatureCardsTabsButton
          card={cards[selectedCardIndex]}
          selectedCardIndex={selectedCardIndex}
          setSelectedCardIndex={setSelectedCardIndex}
          onThumbnailClick={onThumbnailClick}
          variant="minimal"
          disabled={true}
        />
      ) : (
        cards.map(card => (
          <FeatureCardsTabsButton
            card={card}
            selectedCardIndex={selectedCardIndex}
            setSelectedCardIndex={setSelectedCardIndex}
            onThumbnailClick={onThumbnailClick}
            variant={variant}
            key={card.id}
          />
        ))
      )}
    </div>
  )
}
