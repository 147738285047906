import { fetchContentfulProductsBreakdownById } from '@api/graphqlContentFul/productsBreakdown'
import Head from '@components/Head'
import { HeroBannerProps } from '@components/pages/components/HeroBanner'
import DynamicPages, { PageProps } from '@components/pages/dynamic'
import { ProductsBreakdownProps } from '@components/ui/ProductsBreakdown'
import { fetchContentFulData } from '@helpers/contentful'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { ProductsBreakdownItem } from 'types/contentful/graphQlApi'

const PRODUCTS_BREAKDOWN_CONTENTFUL_ID = '2yS9WdrBAjLp9TYzzPZlw8'
const HERO_BANNER_CONTENTFUL_ID = '5zFhbYLAUCGY7XFbQY6VtV'

async function getProductsBreakdown(
  isPreview: boolean
): Promise<ProductsBreakdownItem> {
  const { productsBreakdown } = (await fetchContentfulProductsBreakdownById({
    id: PRODUCTS_BREAKDOWN_CONTENTFUL_ID,
    preview: isPreview
  })) || { productsBreakdown: { items: [] } }

  return productsBreakdown as unknown as ProductsBreakdownItem
}

const fetchProductsBreakdown = async (
  isPreview: boolean
): Promise<(ProductsBreakdownProps & { type: 'productBreakdown' }) | null> => {
  try {
    const productsBreakdown = await getProductsBreakdown(isPreview)

    const data = {
      ...productsBreakdown,
      categories: productsBreakdown?.categories?.items?.map(item => ({
        ...item,
        type: 'cardMinimal'
      })),
      type: 'productBreakdown'
    }

    return data as unknown as ProductsBreakdownProps & {
      type: 'productBreakdown'
    }
  } catch (error) {
    console.log('error: ', error)
    return null
  }
}

function transformHeroBanner(heroBanner: HeroBannerProps) {
  if (heroBanner.images && Object.keys(heroBanner.images).length > 0) {
    return {
      ...heroBanner,
      type: 'imageCarousel',
      images: Object.values(heroBanner.images).map(image => ({
        ...image,
        mobileFillScreen: false
      }))
    }
  }
  return {}
}

type ErrorPageProps = {
  heroBanner?: HeroBannerProps & { type: 'imageCarousel' }
  productBreakdown?: ProductsBreakdownProps
}

function Page(props: ErrorPageProps) {
  const { query } = useRouter()
  const isPreview = query.preview === 'true'
  const [previewPageData, setPreviewPageData] = useState<PageProps[] | null>(
    null
  )

  const staticPageData = [props?.heroBanner, props?.productBreakdown].filter(
    Boolean
  )

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const productBreakdown = await fetchProductsBreakdown(isPreview)

        const heroBanner = (await fetchContentFulData({
          id: HERO_BANNER_CONTENTFUL_ID,
          depth: 10,
          unNest: false,
          isPreview
        })) as HeroBannerProps

        if (heroBanner && productBreakdown) {
          const transformedHeroBanner = transformHeroBanner(
            heroBanner
          ) as HeroBannerProps & { type: 'imageCarousel' }

          const previewData = [transformedHeroBanner, productBreakdown]
          setPreviewPageData(previewData)
        }
      } catch (error) {
        console.error('Failed to fetch page data:', error)
      }
    }
    if (isPreview) {
      fetchPageData()
    }
  }, [isPreview])

  return (
    <>
      <Head
        title="404 Page Not Found"
        desc="  This page cannot be found. Error 404"
        includeNoFollow={true}
      />
      <DynamicPages handle="/404" page={previewPageData || staticPageData} />
    </>
  )
}

export async function getStaticProps() {
  try {
    const productBreakdown = await fetchProductsBreakdown(false)

    const heroBanner = (await fetchContentFulData({
      id: HERO_BANNER_CONTENTFUL_ID,
      depth: 10,
      unNest: false,
      isPreview: false
    })) as HeroBannerProps

    const transformedHeroBanner = transformHeroBanner(
      heroBanner
    ) as HeroBannerProps & { type: 'imageCarousel' }

    return {
      props: { heroBanner: transformedHeroBanner, productBreakdown }
    }
  } catch (error) {
    console.error('Failed to fetch 404 page data:', error)
    return { props: {} }
  }
}

export default Page
